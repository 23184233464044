const Modal = ({ closeModal, children }) => {
  return (
    <div id="modal" className="modal">
      <div className="modal_content">
        <button className="close" onClick={closeModal}>X</button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
