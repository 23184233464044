import AdminLayout from "../../components/AdminLayout/AdminLayout"

const Admin = () => {
    return (
        <AdminLayout>
            <div className="page_ctn">
                <h1>Page d'administration du site</h1>
                <p>Cliquez sur un élément à modifier dans la colonne de gauche</p>
            </div>
        </AdminLayout>

    )
}

export default Admin