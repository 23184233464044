import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Modal from "../Modal/Modal";
import LegalContent from "../LegalContent/LegalContent";

const Footer = () => {
    const [modalVisible, setModalVisible] = useState(false);

    const openLegalModal = (e) => {
        e.preventDefault();
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const titresNavbar = [
        { texte: 'Me contacter', url: 'contact' },
        { texte: 'Mentions Légales', url: '', action: openLegalModal }
    ];

    return (
        <footer className="footer">
            <Navbar titres={titresNavbar} />
            <div className="sign">Among Others Things - 2024</div>
            {modalVisible && (
                <Modal closeModal={closeModal}>
                    <LegalContent />
                </Modal>
            )}
        </footer>
    );
};

export default Footer;
