import arrow_right from "../../img/arrow-next.svg";
import arrow_left from "../../img/arrow-prev.svg";
import { useState } from "react";
import { baseURL } from "../../service/API";

const Slideshow = ({ product }) => {
    const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

    // Combine la couverture et les images supplémentaires
    const images = [
        { url: product.coverUrl, alt: product.alt },
        ...product.images
    ];

    // Navigation vers l'image précédente
    const previousPic = () => {
        setCurrentPictureIndex(currentPictureIndex === 0 ? images.length - 1 : currentPictureIndex - 1);
    };

    // Navigation vers l'image suivante
    const nextPic = () => {
        setCurrentPictureIndex(currentPictureIndex === images.length - 1 ? 0 : currentPictureIndex + 1);
    };

    return (
        <div className="slideshow">
            <img
                className="slideshow__img"
                src={`${baseURL}/images/${images[currentPictureIndex].url}`}
                alt={images[currentPictureIndex].alt}
            />
            {/* Afficher les flèches de navigation si le nombre total d'images est supérieur à 1 */}
            {images.length > 1 && (
                <div>
                    <img
                        onClick={previousPic}
                        src={arrow_left}
                        alt="Précédente"
                        className="slideshow__arrow arrow_left"
                    />
                    <img
                        onClick={nextPic}
                        src={arrow_right}
                        alt="Suivante"
                        className="slideshow__arrow arrow_right"
                    />
                </div>
            )}
        </div>
    );
};

export default Slideshow;
