import ContactForm from "../../components/ContactForm/ContactForm";


const Contact = () => {
    return (
        <div className="contact_ctn">
            <h1>Me Contacter</h1>
            <ContactForm />
        </div>
    )
};

export default Contact;