import Navbar from "../Navbar/Navbar";
import Modal from "../Modal/Modal";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../service/AuthContext";
import LoginForm from "../LoginForm/LoginForm";

const Header = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useAuth();

    const titresNavbar1 = [{ texte: 'Accueil', url: '' }, { texte: 'Créations', url: '#creations' }, { texte: 'Me contacter', url: 'contact' }];

    useEffect(() => {
        // Vérifier si l'utilisateur est authentifié lors du chargement initial de la page
        const token = localStorage.getItem("token");
        if (token) {
            setIsAuthenticated(true);
        }
    }, [setIsAuthenticated]);

    const openModal = () => {
        setModalVisible(true);
    };

    const logout = () => {
        // On supprime le token du local storage et on déconnecte l'utilisateur
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        // Rediriger vers la page d'accueil
        navigate("/");
    };

    const handleChangePage = () => {
        // Naviguer vers la page d'administration
        navigate("/admin");
    };

    return (
        <nav className="header">
            <div className="navbars">
                <Navbar titres={titresNavbar1} />
                <div className="admin">
                    {isAuthenticated ? (
                        <div className="logged_links">
                            <p className="modal_link" onClick={logout}>
                                Logout
                            </p>
                            <p className="modal_link" onClick={handleChangePage}>
                                Modifier
                            </p>
                        </div>
                    ) : (
                        <p className="modal_link" onClick={openModal}>
                            Admin
                        </p>
                    )}
                    {modalVisible && (
                        <Modal closeModal={() => setModalVisible(false)}>
                            <LoginForm closeModal={() => setModalVisible(false)} />
                        </Modal>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Header;
