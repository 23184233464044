import { useEffect, useState } from "react";

function Banner({ image, texts }) {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Définir une temporisation pour changer le texte toutes les X secondes
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000); // Changez ici pour ajuster l'intervalle en millisecondes

    return () => {
      clearInterval(intervalId); // Nettoyer l'intervalle lorsqu'il n'est plus nécessaire
    };
  }, [texts]);


  return (
    <div className="banner">
      <img src={image} alt="Bannière" className="banner__img" />
      <div className="scrollContainer">
        <h1 className="banner__txt">{texts[currentTextIndex]}</h1>
      </div>
    </div>
  )
}

export default Banner