import Slideshow from "../Slideshow/Slideshow";

const SlideshowModal = ({ product, closeModal }) => {


    return (
        <div className="modalOverlay" onClick={closeModal}>
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                <Slideshow product={product}/>
                <button onClick={closeModal} className="closeButton">
                    X
                </button>
            </div>
        </div>
    );
}

export default SlideshowModal;
