import { useState } from "react";
import { fetchInfo } from "../../service/API";

const NewCategoryForm = ({ updateCategories }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    // Ajouter la valeur de 'alt' en utilisant 'name'
    const name = formData.get('name');
    formData.append('alt', name);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(fetchInfo.categories, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setIsSubmitted(true);
        setTimeout(function () {
          e.target.reset(); // On réinitialise le formulaire
          setIsSubmitted(false);
        }, 1000);
        // On appelle la fonction pour mettre à jour les catégories dans le composant parent
        fetch(fetchInfo.categories)
          .then((response) => response.json())
          .then((data) => updateCategories(data))
          .catch((error) =>
            console.error("Erreur lors du chargement des cartes", error)
          );
      } else {
        const errorData = await response.json();
        console.error(
          errorData.message ||
          "Une erreur s'est produite lors de l'ajout de la catégorie."
        );
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'envoi du formulaire",
        error
      );
    }
  };

  return (
    <div className="admin-category-form-wrapper">
      <form id="form" className="admin-category-form" onSubmit={submitForm}>
        <div className="form-item">
          <input type="file" name="image" id="new-image" required />
          {/* <label htmlFor="new-image">Image:</label> */}
        </div>
        <div className="form-item">
          <input type="text" name="name" id="new-name" required />
          <label htmlFor="new-name">Nom:</label>
        </div>
        <button type="submit" className="submit-btn">
          {isSubmitted ? "Envoyé !" : "Envoyer"}
        </button>
      </form>
    </div>
  );
};

export default NewCategoryForm;