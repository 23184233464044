import { baseURL } from "../../service/API";


function CategoryCard({ categories, onClickAction = () => { } }) {
    // Définition de la fonction handleClick qui sera exécutée lors du clic sur la carte
    const handleClick = () => {
        // Lorsque la carte est cliquée, onClickAction est appelée avec les données de la catégorie en tant qu'argument
        // Cela permet de transmettre les données de la catégorie à la fonction onClickAction afin qu'elle puisse effectuer une action basée sur ces données
        onClickAction(categories);
    };

    return (
        <article className="cat_card" onClick={handleClick}>
            <img className="img" src={`${baseURL}/images/${categories.image}`} alt={categories.alt} />
            <div className="overlay">
                <p>{categories.name}</p>
            </div>
        </article>
    );
}

export default CategoryCard