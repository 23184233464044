import { useState, useEffect } from 'react';
import { fetchInfo } from '../../service/API';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import NewCategoryForm from '../../components/NewCategoryForm/NewCategoryForm';
import EditModal from '../../components/EditModal/EditModal';
import AdminLayout from "../../components/AdminLayout/AdminLayout";


const AdminCategories = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);


    const updateCategories = (newCategories) => {
        setCategories(newCategories);
    };

    const handleOpenModal = (category) => {
        setSelectedCategory(category);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedCategory(null);
        setModalOpen(false);
    };


    useEffect(() => {
        // Effectue une requête GET 
        // fetch(fetchInfo.categoriesWithoutProducts)
        fetch(fetchInfo.categories)
            .then(response => response.json())
            .then(data =>
                setCategories(data))
            .catch(error => console.error('Erreur lors du chargement des categories', error));
    }, []);

    return (
        <AdminLayout className="admin-main-content">
            <p className="categories__add-title">Modifier ou supprimer une catégorie</p>
            <div className='categories__part'>
                <section className="categories__part-container">
                    {categories.map(category => (
                        <CategoryCard key={category.id} categories={category} onClickAction={handleOpenModal} />
                    ))}
                </section>
            </div>
            <p className="categories__add-title">Ajouter une catégorie</p>
            <NewCategoryForm updateCategories={updateCategories} />
            {isModalOpen && (
                <EditModal
                    data={selectedCategory}
                    type="category"
                    onClose={handleCloseModal}
                    updateCategories={updateCategories}
                />
            )}
        </AdminLayout>
    );
};

export default AdminCategories