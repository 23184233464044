import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const { exp } = JSON.parse(atob(token.split('.')[1])); // Décodez le payload JWT pour obtenir la date d'expiration
        if (Date.now() >= exp * 1000) {
          // Token expiré
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkToken();

    const interval = setInterval(checkToken, 60000); // Vérifie toutes les minutes
    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
