import { useState, useEffect } from 'react';
import { fetchInfo } from '../../service/API';
import ProductCard from '../../components/ProductCard/ProductCard';
import NewProductForm from '../../components/NewProductForm/NewProductForm';
import EditModal from '../../components/EditModal/EditModal';
import AdminLayout from "../../components/AdminLayout/AdminLayout";
import SlideshowModal from '../../components/SlideshowModal/SlideshowModal';


const AdminProducts = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isCarouselModalOpen, setCarouselModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);


    const updateProducts = (newProducts) => {
        setProducts(newProducts);
    };

    const handleOpenModal = (product) => {// Fonction pour ouvrir la modale d'édition
        setSelectedProduct(product);
        setModalOpen(true);
    };

    const openModal = (product) => { // Fonction pour ouvrir le carrousel
        setSelectedProduct(product);
        setCarouselModalOpen(true);
    };


    const handleCloseModal = () => {
        setSelectedProduct(null);
        setModalOpen(false);
    };

    const closeModal = () => { 
        setCarouselModalOpen(false);
    };
    
    useEffect(() => {
        // Effectue une requête GET pour récupérer les articles
        fetch(fetchInfo.products)
            .then(response => response.json())
            .then(data =>
                setProducts(data))
            .catch(error => console.error('Erreur lors du chargement des articles', error));
        // Effectue une requête GET pour récupérer les catégories
        fetch(fetchInfo.categories)
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Erreur lors du chargement des catégories', error));
    }, []);

    return (
        <AdminLayout className="admin-main-content">
            <p className="products__add-title">Modifier ou supprimer un article</p>
            <div className='products__part'>
                <section className="products__part-container">
                    {products.map(product => (
                        <ProductCard key={product.id} product={product} onClickAction={handleOpenModal} openModal={openModal} />
                    ))}
                    {isCarouselModalOpen && selectedProduct && (
                        <SlideshowModal product={selectedProduct} closeModal={closeModal} />
                    )}

                </section>
            </div>
            <p className="products__add-title">Ajouter un article</p>
            <NewProductForm updateProducts={updateProducts} categories={categories}/>
            {isModalOpen && (
                <EditModal
                    data={selectedProduct}
                    type="product"
                    onClose={handleCloseModal}
                    updateProducts={updateProducts}
                    categories={categories}
                />
            )}
        </AdminLayout>
    );
};

export default AdminProducts