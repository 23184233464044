import { fetchInfo, baseURL } from "../../service/API";
import { useState, useEffect } from "react";

const EditCategoryForm = ({ categoryData, updateCategories }) => {
    const [isModifySubmitted, setIsModifySubmitted] = useState(false);
    const [isDeleteSubmitted, setIsDeleteSubmitted] = useState(false);
    const [deleteError, setDeleteError] = useState(null); // État pour stocker les erreurs de suppression
    const [selectedImage, setSelectedImage] = useState(null);

    console.log(categoryData);

    const [localFormData, setLocalFormData] = useState({
        image: "",
        name: "",
    });

    useEffect(() => {
        setLocalFormData({
            image: categoryData.image || "",
            name: categoryData.name || "",
        });
    }, [categoryData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const imageURL = URL.createObjectURL(file);
        setSelectedImage(imageURL);
        setLocalFormData((prevData) => ({
            ...prevData,
            image: file,
        }));
    };

    // const handleFormSubmit = async (e) => {
    //     e.preventDefault();
    //     const token = localStorage.getItem("token");

    //     // On crée un nouvel objet FormData
    //     const formData = new FormData();
    //     // On ajoute les champs name et alt (utilise name pour alt)
    //     formData.append('name', localFormData.name);
    //     formData.append('alt', localFormData.name);

    //     // On ajoute l'image s'il y en a une nouvelle, sinon on utilise l'image existante
    //     if (localFormData.image instanceof File) {
    //         formData.append('image', localFormData.image);
    //     } else {
    //         // On récupère l'URL de l'image existante à partir des données de la catégorie
    //         const currentImageURL = `${baseURL}/images/${categoryData.image}`;
    //         // On effectue une requête pour récupérer les données binaires de l'image existante
    //         const response = await fetch(currentImageURL);
    //         const imageBlob = await response.blob();
    //         // On ajoute les données binaires de l'image existante au FormData
    //         formData.append('image', imageBlob, categoryData.image);
    //     }

    //     console.log('body :', formData)

    //     try {
    //         const response = await fetch(`${fetchInfo.categories}/${categoryData.id}`, {
    //             method: "PUT",
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: formData,
    //         });

    //         if (response.ok) {
    //             setIsModifySubmitted(true)
    //             setTimeout(function () {
    //                 e.target.reset(); // On réinitialise le formulaire
    //                 setIsModifySubmitted(false);
    //             }, 1000);
    //             // Appeler la fonction pour mettre à jour les catégories dans le composant parent
    //             fetch(fetchInfo.categories)
    //                 .then(response => response.json())
    //                 .then(data => updateCategories(data))
    //                 .catch(error => console.error('Erreur lors du chargement des cartes', error));
    //         } else {
    //             // Gérer les erreurs en fonction de la réponse du serveur
    //             const errorData = await response.json();
    //             console.error("Erreur lors de la requête :", errorData);
    //         }
    //     } catch (error) {
    //         console.error("Une erreur s'est produite lors de la mise à jour de la carte", error);
    //         // Gérer les erreurs ici
    //     }
    // };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
    
        const formData = new FormData();
        formData.append('name', localFormData.name);
        formData.append('alt', localFormData.name);
    
        if (localFormData.image instanceof File) {
            formData.append('image', localFormData.image);
        } else {
            formData.append('existingImage', localFormData.image);
        }
    
        try {
            const response = await fetch(`${fetchInfo.categories}/${categoryData.id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
    
            if (response.ok) {
                setIsModifySubmitted(true);
                setTimeout(() => {
                    e.target.reset();
                    setIsModifySubmitted(false);
                }, 1000);
                fetch(fetchInfo.categories)
                    .then(response => response.json())
                    .then(data => updateCategories(data))
                    .catch(error => console.error('Erreur lors du chargement des cartes', error));
            } else {
                const errorData = await response.json();
                console.error("Erreur lors de la requête :", errorData);
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la mise à jour de la catégorie", error);
        }
    };
    

    const handleDelete = async () => {
        const token = localStorage.getItem("token");
        try {
            // Effectuer la requête DELETE pour supprimer la catégorie
            const response = await fetch(`${fetchInfo.categories}/${categoryData.id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setIsDeleteSubmitted(true);
                // Appeler la fonction pour mettre à jour les catégories dans le composant parent
                fetch(fetchInfo.categories)
                    .then((response) => response.json())
                    .then((data) => updateCategories(data))
                    .catch((error) =>
                        console.error("Erreur lors du chargement des cartes", error)
                    );
            } else {
                const errorData = await response.json();
                setDeleteError(errorData.error); // Mettez à jour l'état avec le message d'erreur
                console.error("Erreur lors de la requête :", errorData);
            }
        } catch (error) {
            console.error(
                "Une erreur s'est produite lors de la suppression de la catégorie",
                error
            );
        }
    };

    return (
        <div className="edit-category-form-wrapper">
            <form id='form' className="edit-category-form" onSubmit={handleFormSubmit}>
                <div className="img-edition">
                    <img className="img_to-edit" src={selectedImage || `${baseURL}/images/${categoryData.image}`} alt={categoryData.alt} />
                    <label htmlFor="image" className="img_edit-btn">Changer l'image</label>
                    <input type="file" name="image" id="image" onChange={handleFileChange} className="img_edit-input" />
                </div>

                <div className="form-item">
                    <input type="text" name="name" id="name" value={localFormData.name} required onChange={handleInputChange} />
                    <label htmlFor="name">Nom:</label>
                </div>
                <button type="submit" className={`submit-btn ${isModifySubmitted ? 'modified' : ''}`}>
                    {isModifySubmitted ? "Modifié !" : "Modifier"}
                </button>
            </form>
            <button onClick={handleDelete} className={`delete-btn ${isDeleteSubmitted ? 'deleted' : ''}`}>
                {isDeleteSubmitted ? "Supprimé !" : "Supprimer"}
            </button>
            {deleteError && <p className="error-message">{deleteError}</p>}
        </div>
    );
};

export default EditCategoryForm;
