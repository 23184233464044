const Navbar = ({ titres }) => {
    return (
        <nav>
            <ul className="navbar_list">
                {titres.map((titre, index) => (
                    <li className="navbar_link" key={index}>
                        {titre.action ? (
                            <a href={titre.url} onClick={titre.action}>{titre.texte}</a>
                        ) : (
                            <a href={`/${titre.url}`}>{titre.texte}</a>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navbar;
