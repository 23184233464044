import EditCategoryForm from "../EditCategoryForm/EditCategoryForm";
import EditProductForm from "../EditProductForm/EditProductForm";

const EditModal = ({ data, type, onClose, updateCategories, updateProducts,categories }) => {
    return (
      <div className="edit-modal">
        <div className="edit-modal-content">
          {type === "category" && (
            <EditCategoryForm categoryData={data} updateCategories={updateCategories} />
          )}
          {type === "product" && (
            <EditProductForm productData={data} updateProducts={updateProducts} categories={categories}/>
          )}
          <button className="close" onClick={onClose}>
            X
          </button>
        </div>
      </div>
    );
  };
  
  export default EditModal;