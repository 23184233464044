import { Link } from "react-router-dom";

const AdminLayout = ({ children }) => {
    return (
        <div className="layout">
            <aside className="layout_aside">
                <div className="layout_aside_sticky-container">
                    <Link to="/admin/categories"><p>Modifier les catégories</p></Link>
                    <Link to="/admin/products"><p>Modifier les articles</p></Link>
                    <Link to="/"><p>Retour au site</p></Link>
                </div>
            </aside>
            <div className="layout_content">
                {children}
            </div>
        </div>
    );
};

export default AdminLayout;