import { useState, useEffect } from "react";
import { fetchInfo, baseURL } from "../../service/API";

const EditProductForm = ({ productData, updateProducts, categories }) => {
    const [isModifySubmitted, setIsModifySubmitted] = useState(false);
    const [isDeleteSubmitted, setIsDeleteSubmitted] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [existingImages, setExistingImages] = useState([]);

    const [localFormData, setLocalFormData] = useState({
        coverUrl: "",
        name: "",
        description: "",
        price: "",
        category: "",
    });

    useEffect(() => {
        setLocalFormData({
            coverUrl: productData.coverUrl || "",
            name: productData.name || "",
            description: productData.description || "",
            price: productData.price || "",
            category: productData.category_id || "",
        });
        setExistingImages(productData.images || []);
    }, [productData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const imageURL = URL.createObjectURL(file);
        setSelectedImage(imageURL);
        setLocalFormData((prevData) => ({
            ...prevData,
            image: file,
        }));
    };

    const handleMultipleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleRemoveExistingImage = (index) => {
        setExistingImages(existingImages.filter((_, i) => i !== index));
    };

    const handleRemoveSelectedFile = (index) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append('name', localFormData.name);
        formData.append('alt', localFormData.name); // Utilise name pour alt
        formData.append('description', localFormData.description);
        formData.append('price', localFormData.price);
        formData.append('category', localFormData.category);

        if (localFormData.image instanceof File) {
            formData.append('coverUrl', localFormData.image);
        }

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        formData.append('existingImages', JSON.stringify(existingImages));

        try {
            const response = await fetch(`${fetchInfo.products}/${productData.id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setIsModifySubmitted(true);
                setTimeout(() => {
                    e.target.reset();
                    setIsModifySubmitted(false);
                }, 1000);
                fetch(fetchInfo.products)
                    .then(response => response.json())
                    .then(data => updateProducts(data))
                    .catch(error => console.error('Erreur lors du chargement des cartes', error));
            } else {
                const errorData = await response.json();
                console.error("Erreur lors de la requête :", errorData);
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la mise à jour de l'article", error);
        }
    };

    const handleDelete = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(`${fetchInfo.products}/${productData.id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setIsDeleteSubmitted(true);
                fetch(fetchInfo.products)
                    .then((response) => response.json())
                    .then((data) => updateProducts(data))
                    .catch((error) =>
                        console.error("Erreur lors du chargement des cartes", error)
                    );
            } else {
                const errorData = await response.json();
                console.error("Erreur lors de la requête :", errorData);
            }
        } catch (error) {
            console.error(
                "Une erreur s'est produite lors de la suppression de l'article",
                error
            );
        }
    };

    return (
        <div className="edit-product-form-wrapper">
            <form id='form' className="edit-product-form" onSubmit={handleFormSubmit}>
                <div className="img-edition">
                    <img className="img_to-edit" src={selectedImage || `${baseURL}/images/${productData.coverUrl}`} alt={productData.name} />
                    <label htmlFor="coverUrl" className="img_edit-btn">Changer l'image de couverture</label>
                    <input type="file" name="coverUrl" id="coverUrl" onChange={handleFileChange} className="img_edit-input" />
                </div>

                <div className="form-item">
                    <input type="text" name="name" id="name" value={localFormData.name} required onChange={handleInputChange} />
                    <label htmlFor="name">Nom:</label>
                </div>
                <div className="form-item">
                    <textarea name="description" id="description" value={localFormData.description} required onChange={handleInputChange} />
                    <label htmlFor="description">Description:</label>
                </div>
                <div className="form-item">
                    <input type="number" name="price" id="price" value={localFormData.price} required onChange={handleInputChange} />
                    <label htmlFor="price">Prix:</label>
                </div>
                <div className="form-selector">
                    <select name="category" id="category-select" value={localFormData.category} required onChange={handleInputChange}>
                        <option value=''>Choisissez une catégorie</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                    <label htmlFor="category-select">Catégorie:</label>
                </div>
                
                <div className="form-item">
                    <label className="file-label" htmlFor="images">Images supplémentaires:</label>
                    <input type="file" id="images" multiple onChange={handleMultipleFileChange} style={{ display: 'none' }} />
                    <button className="addFile-button" type="button" onClick={() => document.getElementById('images').click()}>
                        Ajouter des images
                    </button>
                    <div className="image-preview">
                        {existingImages.map((img, index) => (
                            <div key={index}>
                                <img src={`${baseURL}/images/${img.url}`} alt={img.alt} />
                                <button type="button" className="remove-button" onClick={() => handleRemoveExistingImage(index)}>Supprimer</button>
                            </div>
                        ))}
                        {selectedFiles.map((file, index) => (
                            <div key={index}>
                                <img src={URL.createObjectURL(file)} alt={`vue-${index}`} />
                                <button type="button" className="remove-button" onClick={() => handleRemoveSelectedFile(index)}>Supprimer</button>
                            </div>
                        ))}
                    </div>
                </div>

                <button type="submit" className={`submit-btn ${isModifySubmitted ? 'modified' : ''}`}>
                    {isModifySubmitted ? "Modifié !" : "Modifier"}
                </button>
            </form>
            <button onClick={handleDelete} className={`delete-btn ${isDeleteSubmitted ? 'deleted' : ''}`}>
                {isDeleteSubmitted ? "Supprimé !" : "Supprimer"}
            </button>
        </div>
    );
};

export default EditProductForm;
