const LegalContent = () => (
    <div className="legalContent">
        <h2>Mentions Légales</h2>
        <h3>JE COUDS COMME CA. C'BALLOT</h3>
        <p>SIREN :	902 645 761<br />
            SIRET (siège) :	902 645 761 00013<br />
            Forme juridique :	Entrepreneur individuel<br />
            Numéro de TVA: FR32902645761</p>

        <h4>Coordonnées :</h4>
        <p>Catherine BALLOT<br />
            4 chemin des marais, 91760 Itteville<br />
            cballot-crea@orange.fr</p>

        <h4>Crédits :</h4>
        <p>Photo bannière: Unsplash<br />
            Autres photos : Catherine BALLOT</p>

        <h4>Hébergeur :</h4>
        <p>o2switch<br />
            Chemin des Pardiaux 63000 Clermont-Ferrand</p>
    </div>
);

export default LegalContent;
