import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchInfo } from "../../service/API";
import Banner from "../../components/Banner/Banner";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import img from "../../img/ruban.webp"
import logo from "../../img/dessin.svg";


const Home = () => {

  const [categories, setCategories] = useState([]);
  const location = useLocation();

  const bannerTexts = [
    "Créations originales",
    "Entièrement personnalisables",
    "Fabrication artisanale",
    // Ajouter autant de textes que nécessaire
  ];

  useEffect(() => {
    // Effectue une requête GET 
    // fetch(fetchInfo.categoriesWithoutProducts)
    fetch(fetchInfo.categories)
      .then(response => response.json())
      .then(data =>
        setCategories(data))
      .catch(error => console.error('Erreur lors du chargement des catégories', error));
  }, []);
  console.log("Category Data:", categories);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 300); // Délai de 300ms
    }
  }, [location]);


  return (
    <main className="main-content">
      <div className="nav_logo">
        <img
          src={logo}
          alt="Logo"
        />
      </div>
      < Banner image={img} texts={bannerTexts} />
      <section id='creations' className="main__categories">
        <h1 className="main__categories__title" >Découvrez mes créations</h1>
        <div className="main__categories__cards" >{categories.map(categories => (
          <CategoryCard key={categories.id} categories={categories} onClickAction={() => { window.location.href = `/products/${categories.id}`; }} />
        ))}
        </div>
      </section>
    </main>
  );
};

export default Home;
