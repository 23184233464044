
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './service/AuthContext';
import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Products from './pages/Products/Products';
import Admin from './pages/Admin/Admin';
import AdminCategories from './pages/AdminCategories/AdminCategories';
import AdminProducts from './pages/AdminProducts/AdminProducts';
import Error from './pages/Error/Error';


const App = () => {
  const { isAuthenticated } = useAuth(); // Utilisez le hook useAuth pour accéder à l'état d'authentification
  console.log({isAuthenticated})
  return (
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={isAuthenticated ? <Admin /> : <Navigate to="/" />} />
          <Route path="/admin/categories" element={isAuthenticated ? <AdminCategories /> : <Navigate to="/" />} />
          <Route path="/admin/products" element={isAuthenticated ? <AdminProducts /> : <Navigate to="/" />} />
          <Route path="/products/:id" element={<Products />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
  );
};

export default App;