import infoIcone from "../../img/info.svg";
import loupeIcone from "../../img/loupe.svg";
import { baseURL } from "../../service/API";
// import Slideshow from "../Slideshow/Slideshow";


function ProductCard({ product, openModal, onClickAction = () => {} }) {

    const handleClick = () => {
        // Lorsque la carte est cliquée, onClickAction est appelée avec les données de la catégorie en tant qu'argument
        // Cela permet de transmettre les données de la catégorie à la fonction onClickAction afin qu'elle puisse effectuer une action basée sur ces données
        onClickAction(product);
    };

    const handleOpenCarousel = (event) => {
        event.stopPropagation();
        openModal(product); // Passer le produit à la fonction openModal
    };

    return (
        <div className="card" onClick={handleClick}>
            <div className="card__content">
                <img
                    onClick={handleOpenCarousel}
                    src={loupeIcone}
                    alt="Icone loupe"
                    className="card__content__loupe"
                    title="Voir plus"
                />
                {/* <Slideshow product={product} /> */}
                <img 
                src={`${baseURL}/images/${product.coverUrl}`} 
                alt={product.name}
                className="card__content__cover"></img>
                <div className="card__content__bottom">
                    <div className="details">
                        <h1 className="details__title">{product.name}</h1>
                        <p className="details__price">{`${product.price} €`}</p>
                    </div>
                </div>
            </div>
            <div className="card__inside">
                <img
                    src={infoIcone}
                    alt="Icone info"
                    className="card__inside__icon"
                />
                <div className="card__inside__contents">
                    <h2 className="contents__title">{product.name}</h2>
                    <p className="contents__text">{product.description}</p>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
