import { useState } from "react";
import emailjs from "@emailjs/browser";
import facebook from "../../img/facebook2.svg";
import instagram from "../../img/instagram2.svg";

function ContactForm() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        emailjs
            .sendForm(
                "service_y1zk2ca",
                "template_srv8l8r",
                "#form",
                "E3TXX06dO2hDe4KFG"
            )
            .then(
                (result) => {
                    setIsSubmitted(true);
                    console.log(result.text);
                    setTimeout(function () {
                        event.target.reset(); // On réinitialise le formulaire
                        setIsSubmitted(false);
                    }, 1000);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className="contact">
            <div className="contact_links">
                <h2 className="contact_links_title">Vous souhaitez commander ? <br /> Obtenir une information ou un devis ? <br />N'hésitez pas, contactez-moi !</h2>
                <div className="links">
                    <div className="link">
                        <a
                            href="https://www.facebook.com/profile.php?id=100078007532307"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={facebook} alt="facebook" />
                        </a>
                    </div>
                    <div className="link">
                        <a
                            href="https://www.instagram.com/p/CpIwcGmr0lK/?img_index=1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={instagram} alt="instagram" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="contact_form_wrapper">
                <form id="form" className="contact_form" onSubmit={handleSubmit}>
                    <div className="form_item">
                        <input type="text" name="sender" id="sender" required />
                        <label htmlFor="sender">Nom:</label>
                    </div>
                    <div className="form_item">
                        <input type="email" name="email" id="email" required />
                        <label htmlFor="email">Email:</label>
                    </div>
                    <div className="form_item">
                        <textarea
                            type="text"
                            name="message"
                            id="message"
                            required
                        ></textarea>
                        <label htmlFor="message">Message:</label>
                    </div>
                    <button type="submit" className="submit_btn">
                        {isSubmitted ? "Envoyé !" : "Envoyer"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ContactForm