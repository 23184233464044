import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = ({ children }) => {
  return (
    <div className='main-container'>
      <Header />
      <main className='main-container__cnt'>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;