import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { fetchInfo } from "../../service/API";
import { useAuth } from "../../service/AuthContext";

const LoginForm = ({ closeModal }) => {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(fetchInfo.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, rememberMe }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        closeModal();
        navigate("/admin");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la connexion", error);
      setError("Une erreur s'est produite lors de la connexion.");
    }
  };

  return (
    <div className="login_form_wrapper">
      <form className="login_form" onSubmit={submitForm}>
        <div className="form_item">
          <input type="email" name="email" id="user" value={formData.email} onChange={handleChange} required />
          <label htmlFor="user">Email:</label>
        </div>
        <div className="form_item">
          <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} required />
          <label htmlFor="password">Password:</label>
        </div>
        <div className="form_check_item">
        <label htmlFor="rememberMe">Se souvenir de moi</label>
          <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={handleCheckboxChange} />
          
        </div>
        {error && <div className="error_message">{error}</div>}
        <button type="submit" className="submit_btn">
          Se connecter
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
