import { useState, useRef } from "react";
import { fetchInfo } from "../../service/API";

const NewProductForm = ({ updateProducts, categories }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [coverFile, setCoverFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const coverFileInputRef = useRef(null);

  const handleCoverChange = (e) => {
    setCoverFile(e.target.files[0]);
  };

  const handleCoverRemove = () => {
    setCoverFile(null);
    if (coverFileInputRef.current) {
      coverFileInputRef.current.value = null;
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
    e.target.value = null;
  };

  const handleFileRemove = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    // Ajouter la valeur de 'alt' en utilisant 'name'
    const name = formData.get('name');
    formData.append('alt', name);

    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(fetchInfo.products, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setIsSubmitted(true);
        setTimeout(() => {
          form.reset();
          setIsSubmitted(false);
          setCoverFile(null);
          setSelectedFiles([]);
        }, 1000);
        fetch(fetchInfo.products)
          .then((response) => response.json())
          .then((data) => updateProducts(data))
          .catch((error) =>
            console.error("Erreur lors du chargement des cartes", error)
          );
      } else {
        const errorData = await response.json();
        console.error(
          errorData.message ||
          "Une erreur s'est produite lors de l'ajout de l'article."
        );
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'envoi du formulaire",
        error
      );
    }
  };

  return (
    <div className="admin-product-form-wrapper">
      <form id="form" className="admin-product-form" onSubmit={submitForm}>
        <div className="form-item">
          <label className="file-label" htmlFor="new-image">Image de couverture:</label>
          <input
            type="file"
            name="coverUrl"
            id="new-image"
            onChange={handleCoverChange}
            ref={coverFileInputRef}
            style={{ display: coverFile ? 'none' : 'block' }}
            required
          />
          {coverFile && (
            <div className="file-preview">
              <img src={URL.createObjectURL(coverFile)} alt={coverFile.name} />
              <button type="button" className="remove-button" onClick={handleCoverRemove}>
                Supprimer
              </button>
            </div>
          )}
        </div>
        <div className="form-item">
          <label className="file-label" htmlFor="new-images">Images supplémentaires:</label>
          <input
            type="file"
            id="new-images"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <button className="addFile-button" type="button" onClick={() => document.getElementById('new-images').click()}>
            Ajouter des images
          </button>
          <div className="file-preview">
            {selectedFiles.map((file, index) => (
              <div key={index}>
                <img src={URL.createObjectURL(file)} alt={file.name} />
                <button type="button" className="remove-button" onClick={() => handleFileRemove(index)}>
                  Supprimer
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="form-item">
          <input type="text" name="name" id="new-name" required />
          <label htmlFor="new-name">Nom:</label>
        </div>
        <div className="form-item">
          <textarea name="description" id="new-description" required />
          <label htmlFor="new-description">Description:</label>
        </div>
        <div className="form-item">
          <input type="number" name="price" id="new-price" required />
          <label htmlFor="new-price">Prix:</label>
        </div>
        <div className="form-selector">
          <select name="category" id="category-select" required>
            <option value=''>Choisissez une catégorie</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
          <label htmlFor="category-select">Catégorie:</label>
        </div>
        <button type="submit" className="submit-btn">
          {isSubmitted ? "Envoyé !" : "Envoyer"}
        </button>
      </form>
    </div>
  );
};

export default NewProductForm;