import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchInfo } from "../../service/API";
import ProductCard from "../../components/ProductCard/ProductCard";
import SlideshowModal from "../../components/SlideshowModal/SlideshowModal"; 
import RetourIcone from "../../img/arrow-back.svg";

const Products = () => {
    const { id } = useParams();
    const [categoryWithProducts, setCategoryWithProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        fetch(`${fetchInfo.categories}/${id}`)
            .then(response => response.json())
            .then(data => {
                setCategoryWithProducts(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors du chargement de la catégorie avec les produits', error);
                setLoading(false);
            });
    }, [id]);

    const openModal = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!categoryWithProducts) {
        return <div>Catégorie non trouvée</div>;
    }

    const hasProducts = categoryWithProducts.products && categoryWithProducts.products.length > 0;

    return (
        <section className="products__ctn">
            <h1 className="products__ctn__title">{`Découvrez mes ${categoryWithProducts.name}`}</h1>
            <div className="products__ctn__cards">
                {hasProducts ? (
                    categoryWithProducts.products.map(product => (
                        <ProductCard key={product.id} product={product} openModal={openModal} />
                    ))
                ) : (
                    <div className="no-products">
                        <p>Il n'y a aucun produit disponible dans cette catégorie pour le moment.</p>
                    </div>
                )}
            </div>
            {isModalOpen && selectedProduct && (
                <SlideshowModal product={selectedProduct} closeModal={closeModal} />
            )}
            <div className="side-nav">
                <a href="/#creations" className="side-nav__returnBtn">
                    <img src={RetourIcone} alt="Retour" className="side-nav__returnBtn__icon" />
                </a>
                {hasProducts && <a href="/contact" className="side-nav__orderBtn"><p>Commander</p></a>}
            </div>
        </section>
    );
};

export default Products;
