export const baseURL = 
"//back.cballot-crea.fr" 
// "http://localhost:3001";

export const fetchInfo = {
products: `${baseURL}/api/products`,
categories: `${baseURL}/api/categories`,
// categoriesWithoutProducts: `${baseURL}/api/categories/without-products`,
// signup: `${baseURL}/api/auth/signup`,
login: `${baseURL}/api/auth/login`
}
